import { Redirect, useLocation, useParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import type { LocationState } from "src/App";
import { addPassThroughKeys } from "src/services/passThroughKey";
import { PassThroughKeys, usePassThroughKeys } from "src/hooks/usePassThroughKeys";
import { useRedirectUri } from "src/hooks/useRedirectUri";
import { KeycloakTimeout } from "src/components/KeycloakLoading/KeycloakTimeout";
import { Panel } from "@k8slens/lds";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import LinkButton from "src/components/Button/TrackedRouterButton";
import { openUrl } from "src/utils/url";
import styles from "./SSOSignIn.module.css";
import { useGetSSOByBusinessHandle } from "../../../hooks/useGetSSOByBusinessHandle";
import { useKeycloakTimeout } from "src/hooks/useKeycloakTimeout";
import { useKeycloakLoading } from "src/hooks/useKeycloakLoading";
import { KeycloakLoading } from "src/components/KeycloakLoading/KeycloakLoading";

const getAuthenticatedUri = (fromPathname: string | undefined, passThroughKeys: PassThroughKeys) => {
  if (fromPathname) {
    return addPassThroughKeys(fromPathname, passThroughKeys);
  }

  return addPassThroughKeys("/home", passThroughKeys);
};

const SSOSignIn = () => {
  const { handle } = useParams<{ handle?: string }>();
  const passThroughKeys = usePassThroughKeys();
  const location = useLocation<LocationState | undefined>();
  const defaultRedirectUri = getAuthenticatedUri(location.state?.from?.pathname, passThroughKeys);
  const { keycloak } = useKeycloak();
  const keycloakTimeout = useKeycloakTimeout();
  const keycloakLoading = useKeycloakLoading();
  const redirectUriParam = useRedirectUri();

  const { loginUrl, loading } = useGetSSOByBusinessHandle(handle);

  if (keycloakTimeout) {
    return <KeycloakTimeout />;
  }

  if (keycloakLoading) {
    return <KeycloakLoading />;
  }

  if (keycloak?.authenticated && !redirectUriParam) {
    return <Redirect to={defaultRedirectUri} />;
  }

  if (loginUrl) {
    openUrl(loginUrl);
  }

  return (
    <PublicLayout>
      <Panel
        className={styles.container}
        header={
          <>
            <h2>Login with SSO</h2>
          </>
        }
        footer={<LinkButton to="/home" label="Return to home page" primary />}
      >
        {loading && <div>Redirecting to SSO provider</div>}
        {handle && !loginUrl && (
          <div>
            <p>Identity provider not found</p>
            <p>
              <LinkButton to="/signin" discreet label="Try another way to Sign In" primary />
            </p>
          </div>
        )}
      </Panel>
    </PublicLayout>
  );
};

export default SSOSignIn;
