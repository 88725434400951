import { useContext, useCallback, useState } from "react";

import Layout from "src/components/Layout/Layout";
import { useLogout } from "src/hooks/useLogout";
import { ProfileContext } from "src/providers/profile-provider";
import { Notification } from "@k8slens/lds";
import { useAddEmails } from "src/hooks/useAddEmails";
import { getUniqueEmailsArray } from "src/services/email";
import { useGetEmails } from "src/hooks/useGetEmails";
import { useDeleteEmails } from "src/hooks/useDeleteEmails";
import { useSetPrimaryEmail } from "src/hooks/useSetPrimaryEmail";
import { useSendVerificationEmail } from "src/hooks/useSendVerificationEmail";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageAction from "src/components/PageAction/PageAction";

import { AddEmailModal } from "./AddEmailModal";
import { EmailTable } from "./EmailTable";

import styles from "./page.module.css";

const AddEmailLabel = "Add Email Address";

export const Emails = () => {
  const logout = useLogout();
  const { profile } = useContext(ProfileContext);
  const { emails, reload: reloadEmails } = useGetEmails();
  const [addEmailModalOpen, setAddEmailModalOpen] = useState(false);
  const { addEmails, error: addEmailError, loading: addingEmails } = useAddEmails();
  const { deleteEmails, error: deleteEmailError } = useDeleteEmails();
  const { setPrimaryEmail, error: setPrimaryEmailError } = useSetPrimaryEmail();
  const { sendVerificationEmail, error: sendVerificationEmailError } = useSendVerificationEmail();

  const openInviteAdministrationModal = useCallback(() => {
    setAddEmailModalOpen(true);
  }, []);
  const closeAddEmailModal = useCallback(() => {
    setAddEmailModalOpen(false);
  }, []);
  const onClickAddEmails = useCallback(
    async (newEmails: string) => {
      const emailArray = getUniqueEmailsArray(newEmails);

      const exception = await addEmails(emailArray);

      // if successfully added email, close the modal
      if (!exception) {
        await reloadEmails();
        closeAddEmailModal();
      }
    },
    [reloadEmails, closeAddEmailModal, addEmails],
  );
  const onSendVerificationEmail = useCallback(
    async (email: string) => {
      await sendVerificationEmail(email);
    },
    [sendVerificationEmail],
  );
  const onSetPrimaryEmail = useCallback(
    async (email: string) => {
      const exception = await setPrimaryEmail(email);

      if (!exception) {
        await reloadEmails();
      }
    },
    [setPrimaryEmail, reloadEmails],
  );
  const onDeleteEmails = useCallback(
    async (email: string[]) => {
      const exception = await deleteEmails(email);

      if (!exception) {
        await reloadEmails();
      }
    },
    [deleteEmails, reloadEmails],
  );

  const requestError = deleteEmailError ?? setPrimaryEmailError ?? sendVerificationEmailError;

  return (
    <Layout className="bg-secondary" logout={logout} profile={profile}>
      <PageHeader title="Emails" subtitle="All email addresses associated with your account are listed in here." />
      {requestError ? (
        <Notification
          level="error"
          message={requestError?.message ?? "Something went wrong"}
          type="flash"
          className={styles.errorNotification}
        />
      ) : null}
      <EmailTable
        emails={emails}
        onSendVerificationEmail={onSendVerificationEmail}
        onSetPrimaryEmail={onSetPrimaryEmail}
        onDeleteEmails={onDeleteEmails}
      />
      <PageAction
        label={AddEmailLabel}
        buttonProps={{
          onClick: () => {
            openInviteAdministrationModal();
          },
        }}
      />
      <AddEmailModal
        open={addEmailModalOpen}
        addingEmails={addingEmails}
        addEmailError={addEmailError}
        onModalClose={closeAddEmailModal}
        onClickAddEmails={onClickAddEmails}
      />
    </Layout>
  );
};
