import { Switch, Route, Redirect } from "react-router-dom";

import { useTrackPageView } from "./hooks/useTrackPageView";
import { useAnalyticIdentifyUser } from "./hooks/useAnalyticIdentifyUser";

import Invitation from "src/components/Invitation/Invitation";
import PrivateRoute from "src/components/PrivateRoute/PrivateRoute";

import { AcceptBusinessInvitation } from "src/components/pages/AcceptBusinessInvitation/AcceptBusinessInvitation";
import Activate from "src/components/pages/Activate/Activate";
import LensLauncher from "src/components/pages/LensLauncher/LensLauncher";
import SignInPage from "src/components/pages/SignInPage/SignInPage";
import SignUpPage from "src/components/pages/SignUpPage/SignUpPage";
import Subscribe from "src/components/pages/Subscribe/Subscribe";
import Support from "src/components/pages/Support/Support";
import Discuss from "src/components/pages/Discuss/Discuss";
import VerifySecondaryEmail from "src/components/pages/VerifySecondaryEmail/VerifySecondaryEmail";
import { NotFound } from "src/components/pages/Error/NotFound";
import { AcceptBusinessChildrenInvitation } from "src/components/pages/AcceptBusinessChildrenInvitation/AcceptBusinessChildrenInvitation";
import { businessChildInvitationTokenParameter } from "src/hooks/useBusinessChildInvitationToken";
import { BillingDetails } from "src/components/pages/Billing/BillingDetails";
import { BusinessBillingDetails } from "./components/pages/Billing/BusinessBillingDetails";
import SSOSignIn from "src/components/pages/SignInPage/SSOSignIn";
import { ConfirmChildAttachment } from "src/components/pages/ConfirmChildAttachment/ConfirmChildAttachment";

import BusinessBase from "./components/Business/Base";
import RedirectToFirstBusiness from "src/components/Business/RedirectToFirstBusiness";
import { BusinessAuth } from "./components/Business/Auth";
import BusinessParentAndChildSettings from "src/components/Business/AccountHierarchy";

import { Account } from "./app/account/page";
import { BusinessHome } from "./app/business/[businessId]/page";
import { ConnectedAcounts } from "./app/connected-accounts/page";
import { Emails } from "./app/emails/page";
import { HomeOrBusiness } from "./app/home/HomeOrBusiness";
import { HomePage } from "./app/home/page";
import { Profile } from "./app/profile/page";
import { SubscribeForTeamsPage } from "./app/subscribe-for-teams/page";
import BusinessAccount from "./app/business/[businessId]/account/page";
import BusinessFeatureFlagSettings from "./app/business/[businessId]/feature-flag-settings/page";
import BusinessProfile from "./app/business/[businessId]/profile/page";
import BusinessUsers from "./app/business/[businessId]/users/page";
import UpdateBusinessSubscription from "./app/business/[businessId]/subscriptions/[subscriptionId]/page";

export const Routes = () => {
  useTrackPageView();
  useAnalyticIdentifyUser();

  return (
    <Switch>
      <Route path="/lens-launcher">
        <LensLauncher />
      </Route>
      <Route
        path={[
          "/signin/realms/:realm?/protocol/openid-connect/auth",
          "/signin/invitation-key/:invitationKey",
          "/signin/activation-key/:activationKey",
          "/signin/business-invitation-key/:businessInvitationKey",
          `/signin/business-child-invitation-token/:${businessChildInvitationTokenParameter}`,
          "/signin",
        ]}
      >
        <SignInPage />
      </Route>
      <Route path="/sso/:handle">
        <SSOSignIn />
      </Route>
      <Route
        path={[
          "/signup/invitation-key/:invitationKey",
          "/signup/activation-key/:activationKey",
          "/signup/business-invitation-key/:businessInvitationKey",
          `/signup/business-child-invitation-token/:${businessChildInvitationTokenParameter}`,
          "/signup",
        ]}
      >
        <SignUpPage />
      </Route>
      <Route path="/verify-secondary-email" exact>
        <VerifySecondaryEmail />
      </Route>
      <Route path="/confirm-child-attachment" exact>
        <ConfirmChildAttachment />
      </Route>
      <PrivateRoute path="/logged-in">
        <Redirect to="/" />
      </PrivateRoute>
      <PrivateRoute path={["/home/activate", "/subscriptions/activate"]} exact>
        <Activate />
      </PrivateRoute>
      <PrivateRoute path="/business-invitation" exact>
        <AcceptBusinessInvitation />
      </PrivateRoute>
      <PrivateRoute path={"/billing-details"} exact>
        <BillingDetails />
      </PrivateRoute>
      <PrivateRoute path="/home" exact>
        <HomePage />
      </PrivateRoute>
      <PrivateRoute path="/business" exact>
        <RedirectToFirstBusiness />
      </PrivateRoute>
      <PrivateRoute path="/business/:businessId/subscriptions/:subscriptionId" exact>
        <UpdateBusinessSubscription />
      </PrivateRoute>
      <Route path={"/business/:businessId"} exact={false}>
        <BusinessBase>
          <Switch>
            <PrivateRoute path="/business/:businessId" exact>
              <BusinessHome />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/parent-child-settings" exact>
              <BusinessParentAndChildSettings />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/feature-flag-settings" exact>
              <BusinessFeatureFlagSettings />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/profile" exact>
              <BusinessProfile />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/account" exact>
              <BusinessAccount />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/auth" exact>
              <BusinessAuth />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/billing-details" exact>
              <BusinessBillingDetails />
            </PrivateRoute>
            <PrivateRoute path="/business/:businessId/users" exact={false}>
              <BusinessUsers />
            </PrivateRoute>
            <PrivateRoute>
              {({ match }) => {
                return <Redirect to={`/business/${match?.params.businessId}`} />;
              }}
            </PrivateRoute>
          </Switch>
        </BusinessBase>
      </Route>
      <PrivateRoute path="/support">
        <Support />
      </PrivateRoute>
      <PrivateRoute path="/forums">
        <Discuss />
      </PrivateRoute>
      <PrivateRoute path="/home/invitation-key/:invitationKey">
        <Invitation />
      </PrivateRoute>
      <PrivateRoute path={`/home/business-child-invitation-token/:${businessChildInvitationTokenParameter}`}>
        <AcceptBusinessChildrenInvitation />
      </PrivateRoute>
      <Route path={["/subscribe", "/subscribe-personal"]}>
        <Subscribe />
      </Route>
      {/* Legacy path for backward compatibiliy */}
      <Route path="/subscribe-for-teams" exact={false}>
        <SubscribeForTeamsPage />
      </Route>
      <PrivateRoute path="/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute path="/account">
        <Account />
      </PrivateRoute>
      <PrivateRoute path="/connected-accounts">
        <ConnectedAcounts />
      </PrivateRoute>
      <PrivateRoute path="/emails">
        <Emails />
      </PrivateRoute>
      <PrivateRoute path="/" exact>
        <HomeOrBusiness />
      </PrivateRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
