import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ButtonBar, Notification } from "@k8slens/lds";

import { TokenContext } from "src/providers/token-provider";
import { ProfileContext } from "src/providers/profile-provider";
import { useLicenseActivation } from "src/hooks/useActivateLicense";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import Feedback from "src/components/Feedback/Feedback";

import styles from "./Success.module.css";

export function Success() {
  const { profile } = useContext(ProfileContext);
  const history = useHistory();
  const { activateLicense, licenseError } = useLicenseActivation();
  const { updateToken } = useContext(TokenContext);

  const handleStartTrial = async () => {
    const plan = "pro-trial";
    const active = await activateLicense("", plan);

    if (active) {
      updateToken();
      history.push("/home");

      return true;
    }

    return false;
  };

  return (
    <div className={styles.success}>
      <div className={styles.content}>
        {licenseError && (
          <Notification className={styles.flashError} level="error" type="flash" message={licenseError} />
        )}
        <h3>Get Started with Lens Desktop</h3>
        <p>Choose subscription that works for you!</p>
        <img src="/static/media/screenshot-hero-faded.png" alt="Lens Desktop" />
      </div>
      <ButtonBar type="grid" as="footer" className={styles.buttonBar}>
        <LinkButton label="Choose Subscription »" to="/subscribe" primary discreet />
        {profile && <Button label="Start 14-day Free Trial" onClick={handleStartTrial} loadingStateOnPromise primary />}
      </ButtonBar>
      {profile && (
        <Feedback
          role="complementary"
          title="Please let us know how did we do with this process!"
          category="signup"
          className={styles.feedback}
        />
      )}
    </div>
  );
}
