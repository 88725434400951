import { useHistory } from "react-router-dom";
import { Panel, ButtonBar, Notification } from "@k8slens/lds";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { useQuery } from "src/hooks/useQuery";
import { useCallback, useEffect, useMemo } from "react";
import styles from "./ConfirmChildAttachment.module.css";
import { useBusinessChildInvitation } from "src/hooks/useBusinessChildInvitation";

export const ConfirmChildAttachment = () => {
  const history = useHistory();
  const {
    confirmChildInvitation,
    errorConfirmingChildInvitation,
    confirmingChildInvitation,
    confirmingChildInvitationSuccess,
  } = useBusinessChildInvitation();
  const query = useQuery();
  const token = useMemo(() => query.get("token"), [query]);
  const childBusinessId = useMemo(() => query.get("childBusinessId"), [query]);
  const parentBusinessId = useMemo(() => query.get("parentBusinessId"), [query]);

  const confirm = useCallback(async () => {
    if (token && childBusinessId) {
      confirmChildInvitation({ token, childBusinessId });
    }
  }, [token, confirmChildInvitation, childBusinessId]);

  useEffect(() => {
    if (confirmingChildInvitationSuccess) {
      history.push(`/business/${parentBusinessId}/parent-child-settings`);
    }
  }, [confirmingChildInvitationSuccess, parentBusinessId, history]);

  return (
    <PublicLayout size="xl" className={styles.layout}>
      <Panel
        className={styles.panel}
        header={
          <>
            <h2>{"Child Business Attachment"}</h2>
          </>
        }
        footer={
          <ButtonBar type="grid" gridSize={4}>
            <LinkButton to="/home" label="Cancel" discreet />
            <div />
            <div />
            <Button
              label="Confirm"
              onClick={confirm}
              loading={confirmingChildInvitation}
              primary
              disabled={!token || confirmingChildInvitation}
            />
          </ButtonBar>
        }
      >
        <>
          {token && (
            <div className={styles.info}>
              <p>Click the button below to confirm child business attachment.</p>
            </div>
          )}
          {errorConfirmingChildInvitation && (
            <Notification
              level="error"
              message={errorConfirmingChildInvitation.message ?? "Unknown Error"}
              type="flash"
            />
          )}
          {!token && (
            <div className={styles.error}>
              <h4>Something went wrong</h4>
              <p>There seems to be some issues with your invitation token.</p>
              <p>
                Try re-opening the link in your email or trigger the confirmation process again. If that doesn&apos;t
                help, please contact us at{" "}
                <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
              </p>
            </div>
          )}
        </>
      </Panel>
    </PublicLayout>
  );
};
