import { useContext, useMemo, useState } from "react";
import { Panel, ButtonBar } from "@k8slens/lds";

import { ProfileContext } from "src/providers/profile-provider";

import LinkButton from "src/components/Button/TrackedRouterButton";
import Button from "src/components/Button/TrackedButton";
import Feedback from "src/components/Feedback/Feedback";

import { TokenContext } from "src/providers/token-provider";
import { useGetBusinesses } from "src/hooks/useGetBusinesses";
import { useDownloadPersonalSubscriptionOfflineActivationCode } from "src/hooks/useDownloadPersonalSubscriptionOfflineActivationCode";
import { DownloadOfflineActivationCodeModal } from "src/components/DownloadOfflineActivationModal/DownloadOfflineActivationCodeModal";
import { useQuery } from "src/hooks/useQuery";
import { useDownloadActivationCode } from "src/hooks/useDownloadActivationCode";
import { useGetSubscriptionSeats } from "src/hooks/useGetSubscriptionSeats";
import { useGetLicense } from "src/hooks/useGetLicense";
import { LicenseType } from "src/components/pages/Home/license";
import { useBusinessParent } from "src/hooks/useBusinessParent";
import styles from "./ThankYou.module.css";

interface Props {
  loading?: boolean;
}

const openEmailInstructText =
  "We have sent you an email containing instructions how to activate Lens subscription for your team members.";

const ThankYou: React.FC<Props> = ({ loading }) => {
  const { profile } = useContext(ProfileContext);
  const { token } = useContext(TokenContext);
  const query = useQuery();
  const accountCode = query.get("account_code");
  const userId = token?.sub;
  const { businesses } = useGetBusinesses();
  const currentSubscription = useGetLicense();
  const { currentSubscriptionSeatWithBillingPeriod } = useGetSubscriptionSeats(profile?.username);
  const isPurchaseForUserself = !accountCode || accountCode === userId;

  const lensBusinessId = useMemo(
    () => businesses.find((business) => business.id === accountCode),
    [businesses, accountCode],
  );
  const isLensBusinessIdPurchase = lensBusinessId !== undefined;

  const { businessParent } = useBusinessParent(accountCode);

  // If account_code exists and it is not the user id
  // We assume it's redirect back from a "Your Team" lite flow https://github.com/lensapp/lenscloud/pull/1874.
  // or Lens Store checkout flow
  const isBusinessLiteOrLensStore = accountCode && accountCode !== userId && !isLensBusinessIdPurchase;

  const downloadOfflineActivationCode = useDownloadActivationCode();
  const downloadPersonalSubscriptionOfflineActivationCode = useDownloadPersonalSubscriptionOfflineActivationCode();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const handleDownloadActivationCode = () => {
    if (currentSubscriptionSeatWithBillingPeriod?.id && profile?.username) {
      downloadOfflineActivationCode(profile?.username, currentSubscriptionSeatWithBillingPeriod?.id);
      setIsModalOpened(false);

      return;
    }

    if (currentSubscription?.type === LicenseType.personal) {
      downloadPersonalSubscriptionOfflineActivationCode();

      setIsModalOpened(false);
    }
  };

  return (
    <Panel
      className={styles.thankYou}
      loading={loading}
      header={
        <>
          <h2>Thank you for your Lens Subscription!</h2>
          {isPurchaseForUserself ? (
            <p>
              Subscription added to your Lens ID: <strong data-testid="thank-you-username">{profile?.username}</strong>
            </p>
          ) : null}
          {isLensBusinessIdPurchase ? (
            <p>
              Subscription {`${businessParent.length > 0 ? `(billed to ${businessParent[0].name}) ` : ""}`}
              added to your Lens Business ID {lensBusinessId.name}{" "}
            </p>
          ) : null}
        </>
      }
      footer={
        <>
          {isPurchaseForUserself ? (
            <ButtonBar
              className={styles.actions}
              type={!currentSubscriptionSeatWithBillingPeriod ? "centered" : undefined}
            >
              {currentSubscriptionSeatWithBillingPeriod?.id && profile?.username && (
                <Button label="Download Activation Code" primary discreet onClick={() => setIsModalOpened(true)} />
              )}
              <LinkButton to="/lens-launcher" target="_blank" label="Open Lens Desktop" primary />
            </ButtonBar>
          ) : null}
          {isBusinessLiteOrLensStore ? (
            <div className={styles.lensStoreActions}>
              <span className={styles.openEmailInstructText}>{openEmailInstructText}</span>
            </div>
          ) : null}
          {isLensBusinessIdPurchase ? (
            <ButtonBar className={styles.actions} type="centered">
              <LinkButton to={`/business/${lensBusinessId.id}`} label="Open Lens Business ID" primary />
            </ButtonBar>
          ) : null}
          <Feedback
            role="complementary"
            title="Please let us know how we did with the Lens Subscription process!"
            category="subscription"
            className={styles.feedback}
          />
        </>
      }
    >
      <img src="/static/media/thank-you-for-purchase-hero.svg" alt="Thank you!" />
      <DownloadOfflineActivationCodeModal
        isOpened={isModalOpened}
        onDownload={handleDownloadActivationCode}
        onCancel={() => setIsModalOpened(false)}
      />
    </Panel>
  );
};

export default ThankYou;
