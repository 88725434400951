import { useCallback, useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { SideNav, SideNavGroup, UserMenu, UserMenuDefaultHeader } from "@k8slens/lds";
import type { MenuItemGroup } from "@k8slens/lds/lib/es/UserMenu/default-items";
import { ScreenWidthMinLg } from "@k8slens/lds-tokens/lib/web/tokens.base";

import { TipsContext } from "src/providers/tip-provider";
import { useBusinessInfo } from "src/hooks/useBusinessInfo";

import { selectIdStepId, selectIdTipId } from "src/components/Tips/id-select";
import LBIDSelect from "src/components/LBIDSelect/LBIDSelect";

import MainNavigationLink from "./MainNavigationItem";
import { SideNavSettings } from "./SideNavSettings";

import styles from "./Sidebar.module.css";

const breakpointLg = parseInt(ScreenWidthMinLg.replace(/px$/, ""));

type Props = {
  businessId?: string;
  username?: string;
  toggleButtonRef: React.MutableRefObject<HTMLDivElement | null>;
  userMenuItems: Array<MenuItemGroup>;
  user: {
    name: string;
    username: string;
    avatar?: string;
  };
  businessRequestCount?: number;
  isSupportEnabled?: boolean;
  handleLogout: () => void;
  licenseText?: string;
};

export default function Sidebar({
  businessId,
  username,
  toggleButtonRef,
  isSupportEnabled,
  handleLogout,
  userMenuItems,
  user,
  businessRequestCount,
  licenseText,
}: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { isBusinessID, showBillingDetails, loadingBusinesses } = useBusinessInfo(businessId, username);

  const lbidSelectRef = useRef<null | HTMLButtonElement>(null);
  const userMenuRef = useRef<null | HTMLButtonElement>(null);
  const sidebarRef = useRef<null | HTMLDivElement>(null);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const targetEl = e.target && "className" in e.target ? (e.target as Element) : null;

      const targetIsSidebar = sidebarRef?.current && sidebarRef.current.contains(targetEl);
      const targetIsLBIDSelectRef = lbidSelectRef?.current && lbidSelectRef.current.contains(targetEl);
      const targetIsUserMenuRef = userMenuRef?.current && userMenuRef.current.contains(targetEl);
      const targetIsToggleButton = sidebarRef?.current && (toggleButtonRef.current as any).contains(targetEl);
      const targetIsTip = Boolean(targetEl && targetEl.className.match && targetEl.className.match(/lds-tip/));
      const isScreenLg = window.innerWidth >= breakpointLg;

      if (targetIsToggleButton) {
        setSidebarOpen(!sidebarOpen);

        return;
      }

      if (!isScreenLg && targetIsSidebar) {
        if (!sidebarOpen) {
          // Sidebar closed, open it and prevent any bubbling
          setSidebarOpen(true);

          return;
        }

        // Don't close the sidebar if the user clicked on the IDSelect
        if (!targetIsLBIDSelectRef && !targetIsUserMenuRef) {
          // Sidebar open, close it
          setSidebarOpen(false);

          return;
        }
      }

      if (
        !isScreenLg &&
        sidebarOpen &&
        !targetIsSidebar &&
        !targetIsLBIDSelectRef &&
        !targetIsUserMenuRef &&
        !targetIsTip
      ) {
        // Clicked outside when open –> close sidebar
        setSidebarOpen(false);
      }
    },
    [sidebarOpen, userMenuRef, lbidSelectRef, toggleButtonRef, setSidebarOpen, sidebarRef],
  );

  /**
   * Ensure `sidebarOpen` is set to correct boolean by window.innerWidth value
   */
  const ensureOpenOnLg = useCallback(() => {
    if (window.innerWidth >= breakpointLg) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, []);
  const handleResize = useCallback(() => {
    // Close menu when resizing, opens when resizing back to lg
    ensureOpenOnLg();
  }, [ensureOpenOnLg]);

  /**
   * Ensure `sidebarOpen` is set to correct value on mount
   */
  useEffect(() => {
    ensureOpenOnLg();
  }, [ensureOpenOnLg]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClick, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClick);
    };
  }, [handleResize, handleClick]);

  /**
   * Handle Tips
   */
  const { hideStep, showStep } = useContext(TipsContext);

  useEffect(() => {
    let mounted = true;
    let timer: NodeJS.Timeout;

    if (hideStep && showStep) {
      if (sidebarOpen) {
        // Wait for the animation to finish
        timer = setTimeout(() => {
          if (mounted) {
            showStep(selectIdStepId);
          }
        }, 250);
      } else {
        hideStep(selectIdStepId);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      mounted = false;
    };
  }, [hideStep, showStep, sidebarOpen]);

  return (
    <div className={clsx(styles.sidebar, { [styles.sidebarOpen]: sidebarOpen })} ref={sidebarRef}>
      <div className={styles.sidebarContent}>
        <div className={styles.selects}>
          <UserMenu
            buttonRef={userMenuRef}
            className={clsx(styles.userMenu, styles.sidenavUserMenu)}
            user={user}
            loading={!user.username}
            onLogoutClick={handleLogout}
            origin={origin}
            items={userMenuItems}
            header={<UserMenuDefaultHeader licenseText={licenseText} />}
          />
          {isBusinessID ? (
            <>
              <h6 className={styles.lbidHelperText}>Lens Business ID</h6>
              <LBIDSelect
                id={selectIdTipId}
                className={styles.businessMenu}
                buttonRef={lbidSelectRef}
                businessId={businessId}
                sideBarOpen={sidebarOpen}
                username={username}
                loading={loadingBusinesses}
              />
            </>
          ) : null}
        </div>

        <nav className={styles.mainNav}>
          <SideNav className={styles.navigation}>
            <SideNavGroup aria-label="Navigation" className={styles.homeLink}>
              <MainNavigationLink to={businessId ? `/business/${businessId}` : "/home"} title="Home" exact />
            </SideNavGroup>
            <SideNavSettings
              showBillingDetails={showBillingDetails}
              businessId={businessId}
              businessRequestCount={businessRequestCount}
              isSupportEnabled={isSupportEnabled}
            />
          </SideNav>
        </nav>
      </div>
    </div>
  );
}
