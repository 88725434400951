import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { Notification } from "@k8slens/lds";

import { useGetLicense } from "src/hooks/useGetLicense";
import { ProfileContext } from "src/providers/profile-provider";
import { TokenContext } from "src/providers/token-provider";
import { useQuery } from "src/hooks/useQuery";
import { useLogout } from "src/hooks/useLogout";
import { useGetSubscriptionSeats } from "src/hooks/useGetSubscriptionSeats";
import { useDownloadActivationCode } from "src/hooks/useDownloadActivationCode";
import { OFFLINE_ACTIVATION } from "src/constants";
import { useGetUserSSO } from "src/hooks/useGetUserSSO";

import Layout from "src/components/Layout/Layout";
import { DownloadOfflineActivationCodeModal } from "src/components/DownloadOfflineActivationModal/DownloadOfflineActivationCodeModal";
import { UserSSOBanner } from "src/components/UserSSOBanner/UserSSOBanner";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import PageAction from "src/components/PageAction/PageAction";

import { LicenseContainer } from "./LicenseContainer";

import styles from "src/components/pages/Home/Home.module.css";

interface LocationState {
  invitationId: string;
}

const Home = () => {
  const location = useLocation<LocationState | undefined>();
  const { token } = useContext(TokenContext);
  const { profile } = useContext(ProfileContext);
  const keycloak = useKeycloak();
  const query = useQuery();
  const [downloaded, setDownloaded] = useState(false);
  const { currentSubscriptionSeat } = useGetSubscriptionSeats(profile?.username);
  const license = useGetLicense();
  const downloadOfflineActivationCode = useDownloadActivationCode();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { userSSOAccountsConnectionLinks } = useGetUserSSO();

  useEffect(() => {
    if (
      query.get("client_id") &&
      query.get("action") === "download" &&
      !downloaded &&
      keycloak?.keycloak?.clientId === "lens-extension" &&
      currentSubscriptionSeat?.id &&
      profile?.username
    ) {
      downloadOfflineActivationCode(profile.username, currentSubscriptionSeat.id);
      setDownloaded(true);
    }
  }, [
    query,
    downloaded,
    keycloak.keycloak.clientId,
    currentSubscriptionSeat,
    downloadOfflineActivationCode,
    profile?.username,
  ]);

  const handleDownload = () => {
    if (query.get("client_id") && query.get("action") === "download") {
      setDownloaded(false);
      setIsModalOpened(false);
    } else {
      // eslint-disable-next-line xss/no-location-href-assign
      window.location.href = `${window.location.origin}${window.location.pathname}?client_id=lens-extension&action=download`;
    }
  };

  const logout = useLogout();

  const canDownloadOfflineActivationCode = useMemo(
    () => Boolean(license?.features?.includes(OFFLINE_ACTIVATION)),
    [license?.features],
  );

  const isPersonalLicense = useMemo(() => token?.license_type === "personal", [token?.license_type]);

  return (
    <Layout className="bg-secondary" logout={logout} profile={profile}>
      {location.state?.invitationId && (
        <Notification
          level="info"
          type="flash"
          message="You have successfully accepted the Lens Business ID invitation."
        />
      )}
      <div className={styles.home}>
        <div>
          {profile && (
            <>
              <UserSSOBanner userSSOLinks={userSSOAccountsConnectionLinks} />
              <PageHeader
                title="Manage your Lens ID"
                subtitle="Lens ID is your personal identity in the Lens universe."
              />
              <PageSection>
                <PageHeader
                  title="Your Active Lens Subscription"
                  subtitle={
                    <>
                      Some products and services (such as Lens Desktop and Lens Cloud Services) made available to you
                      may require active Lens Subscription. Your currently active subscription is displayed below.
                    </>
                  }
                  type="sub"
                />
                <div className={styles.subscriptions}>
                  <LicenseContainer />
                </div>
              </PageSection>
              {token?.license_type ? (
                <PageSection>
                  <PageHeader
                    title="Lens ID offline activation code"
                    subtitle={
                      <>
                        Activate Lens Desktop with Activation Code. This activation method works for offline and
                        air-gapped environments. This activation code is for your use and should not be shared.
                      </>
                    }
                    type="sub"
                  />
                  <PageAction
                    label="Download Activation Code"
                    buttonProps={{
                      disabled: !canDownloadOfflineActivationCode,
                      onClick: () => setIsModalOpened(true),
                    }}
                  />
                  {!canDownloadOfflineActivationCode && !isPersonalLicense && (
                    <p>
                      <i>
                        <small>Your administrator has disabled the use of offline activation.</small>
                      </i>
                    </p>
                  )}
                  {!canDownloadOfflineActivationCode && isPersonalLicense && (
                    <p>
                      <small>
                        Offline activation is available with a Lens Enterprise subscription. Upgrade{" "}
                        <a className="primary" href="subscribe">
                          here.
                        </a>
                      </small>
                    </p>
                  )}
                </PageSection>
              ) : null}
            </>
          )}
        </div>
      </div>
      {canDownloadOfflineActivationCode && (
        <DownloadOfflineActivationCodeModal
          isOpened={isModalOpened}
          onDownload={handleDownload}
          onCancel={() => setIsModalOpened(false)}
        />
      )}
    </Layout>
  );
};

export default Home;
