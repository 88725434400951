import { decodeBase64, encodeBase64 } from "src/utils/base64";

type SearchParameter = ReturnType<URLSearchParams["get"]>;

export type BusinessInvitation = {
  invitationId: SearchParameter;
  businessId: SearchParameter;
};

/**
 * Encode `BusinessInvitation` into a based64 string.
 */
export const encodeBusinessInvitationKey = (invitation: BusinessInvitation) => {
  // only invitation with invitationId is valid
  if (invitation.invitationId) {
    return encodeBase64(invitation);
  }

  return "";
};

/**
 * Decode the based-encoded activation key back to `Partial<ActivationData>`.
 */
export const decodeBusinessInvitationKey = (
  invitationKey: ReturnType<typeof encodeBusinessInvitationKey>,
): Partial<BusinessInvitation> => decodeBase64(invitationKey);
