import { useContext, useEffect } from "react";
import { NumberBadge, SideNavGroup } from "@k8slens/lds";

import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";
import { TipsContext } from "src/providers/tip-provider";

import { manageSeatsTipId, manageSeatsStepId } from "src/components/Tips/manage-seats";
import MainNavLink from "src/components/Layout/MainNavigationItem";

import styles from "./SideNavSettings.module.css";

interface Props {
  businessId?: string;
  businessRequestCount?: number;
  showBillingDetails?: boolean;
  isSupportEnabled?: boolean;
}

export const SideNavSettings = ({ businessId, businessRequestCount, showBillingDetails, isSupportEnabled }: Props) => {
  const { setStepIsVisible, skipStepIfActive } = useContext(TipsContext);

  const { businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled } = useIsEnterpriseFeaturesEnabled(businessSubscriptions);
  const shouldShowBusinessAuth = enterpriseFeaturesEnabled;

  useEffect(() => {
    setStepIsVisible?.(manageSeatsTipId, true);
  }, [setStepIsVisible]);

  return (
    <SideNavGroup label="Settings">
      <MainNavLink to={businessId ? `/business/${businessId}/profile` : "/profile"} title="Profile" />
      <MainNavLink to={businessId ? `/business/${businessId}/account` : "/account"} title="Account" />
      {!businessId && <MainNavLink to="/connected-accounts" title="Connected Accounts" />}
      {shouldShowBusinessAuth ? <MainNavLink to={`/business/${businessId}/auth`} title="Authentication" /> : null}
      {businessId && <MainNavLink to={`/business/${businessId}/feature-flag-settings`} title="Feature Management" />}
      {businessId ? <MainNavLink to={`/business/${businessId}/billing-details`} title="Billing Details" /> : null}
      {showBillingDetails ? <MainNavLink to="/billing-details" title="Billing Details" /> : null}
      {businessId ? null : <MainNavLink to="/emails" title="Emails" />}
      {businessId ? (
        <MainNavLink
          to={`/business/${businessId}/users`}
          title={
            <span
              className={styles.businessUsers}
              id={manageSeatsTipId}
              onClick={() => {
                skipStepIfActive?.(manageSeatsStepId);
              }}
            >
              <span>Users & Seats</span>
              <NumberBadge
                className={styles.badge}
                type="error"
                value={businessRequestCount}
                title={`${businessRequestCount} pending request(s).`}
              />
            </span>
          }
        />
      ) : null}
      {businessId ? (
        <MainNavLink to="/support" title="Support" />
      ) : (
        <MainNavLink to="/support" title="Support" disabled={!isSupportEnabled} />
      )}
    </SideNavGroup>
  );
};
