import { decodeBusinessInvitationKey } from "src/services/businessIdInvitationKey";
import { useBusinessInvitationKey } from "./useBusinessInvitationKey";
import { useMemo } from "react";

/**
 * Decoding the invitation key
 */
export const useDecodedBusinessInvitationKey = () => {
  const invitationKey = useBusinessInvitationKey();

  const decodedInvitationKey = useMemo(() => {
    let decodedInvitationKey;
    const error = undefined;

    if (invitationKey) {
      try {
        decodedInvitationKey = decodeBusinessInvitationKey(invitationKey);
      } catch (error) {
        return { decodedInvitationKey, error };
      }
    }

    return { decodedInvitationKey, error };
  }, [invitationKey]);

  return decodedInvitationKey;
};
