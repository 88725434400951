import { UserMenu } from "@k8slens/lds";
import { MenuItemGroup } from "@k8slens/lds/lib/es/UserMenu/default-items";
import { UserMenuDefaultHeader } from "@k8slens/lds";

import { ProductName } from "./ProductName";

import styles from "./Header.module.css";

type Props = {
  className: string;
  handleLogout: () => void;
  origin: string;
  user: {
    name: string;
    username: string;
    avatar?: string;
  };
  productName: string;
  userMenuItems: Array<MenuItemGroup>;
  licenseText?: string;
};

export default function Header({
  productName,
  className,
  handleLogout,
  origin,
  user,
  userMenuItems,
  licenseText,
}: Props) {
  return (
    <div className={className}>
      <header className={styles.header}>
        <div className={styles.title}>
          <ProductName title={productName} />
        </div>
        <div></div>
        <div className={styles.userMenuContainer}>
          <UserMenu
            className={styles.userMenu}
            user={user}
            loading={!user.username}
            onLogoutClick={handleLogout}
            origin={origin}
            items={userMenuItems}
            header={<UserMenuDefaultHeader licenseText={licenseText} />}
          />
        </div>
      </header>
    </div>
  );
}
