import { useCallback, useEffect, useMemo, useState } from "react";
import { getData } from "countries-data-list";
import { Notification } from "@k8slens/lds";
import { FormInput } from "@k8slens/lds-form";
import { base } from "@k8slens/lds-icons";

import type { NewBusiness } from "src/hooks/useCreateBusiness";
import { Option } from "src/components/Combobox/Combobox";
import { FormCombobox } from "src/components/FormComponents/FormCombobox";
import clsx from "clsx";
import { dataToFieldValues, FieldDefaultMap } from "src/utils/field-value";

import { businessHandleValidation } from "lens-platform-sdk";
import { Popover } from "@headlessui/react";
import styles from "./BusinessIdForm.module.css";
import { defaultErrorParser } from "../FormComponents/FormSelect";

const { InfoIcon } = base;

const countryOptions: Array<Option> = getData().map(({ code, name }) => ({
  id: code,
  label: name,
}));

export interface BusinessIdFormProps {
  title: string;
  initialData?: NewBusiness;
  fields?: Array<keyof NewBusiness>;
  onChange(newAccount: Partial<NewBusiness>, isValid: boolean): void;
  error?: Error | null;
  singleColumn?: boolean;
  disabled?: boolean;
}

type BusinessForm = {
  [K in keyof Omit<NewBusiness, "country">]: NewBusiness[K];
} & {
  country: Option | null | undefined;
  additionalAddress: string | undefined;
  state: string | undefined;
};

type BusinessFormField = FieldDefaultMap<NewBusiness, BusinessForm>;

const getDataFieldDefaults = (fields?: Array<keyof NewBusiness>): BusinessFormField => {
  const fieldIncluded = (field: keyof NewBusiness) => !fields || fields.includes(field);

  return {
    name: { value: "", valid: fieldIncluded("name") ? false : true },
    phoneNumber: { value: "", valid: fieldIncluded("phoneNumber") ? false : true },
    address: { value: "", valid: fieldIncluded("address") ? false : true },
    additionalAddress: { value: "", valid: fieldIncluded("address") ? undefined : true },
    country: {
      value: undefined,
      valid: fieldIncluded("country") ? false : true,
      transform: (value: undefined | string) => countryOptions.find(({ label }) => label === value),
    },
    state: { value: "", valid: fieldIncluded("state") ? undefined : true },
    city: { value: "", valid: fieldIncluded("city") ? false : true },
    handle: { value: "", valid: fieldIncluded("handle") ? false : true },
    zip: { value: "", valid: fieldIncluded("zip") ? false : true },
    websiteUrl: { value: "", valid: fieldIncluded("websiteUrl") ? false : true },
    department: { value: "", valid: fieldIncluded("department") ? undefined : true },
    verifiedDomains: { value: [], valid: true },
    reseller: { value: false },
    automaticSeatAssignment: { value: true },
  };
};

const BusinessIdForm: React.FC<BusinessIdFormProps> = ({
  title,
  initialData: _initialData,
  fields,
  onChange,
  error,
  disabled = false,
  singleColumn = false,
}) => {
  const initialData = useMemo(
    () => dataToFieldValues(_initialData, getDataFieldDefaults(fields)),
    [_initialData, fields],
  );

  const [name, setName] = useState<BusinessFormField["name"]>(initialData.name);
  const [websiteUrl, setWebsiteUrl] = useState<BusinessFormField["websiteUrl"]>(initialData.websiteUrl);
  const [department, setDepartment] = useState<BusinessFormField["department"]>(initialData.department);
  const [phoneNumber, setPhoneNumber] = useState<BusinessFormField["phoneNumber"]>(initialData.phoneNumber);
  const [address, setStreet] = useState<BusinessFormField["address"]>(initialData.address);
  const [additionalAddress, setAdditionalAddress] = useState<BusinessFormField["additionalAddress"]>(
    initialData.additionalAddress,
  );
  const [country, setCountry] = useState<BusinessFormField["country"]>(initialData.country);
  const [state, setState] = useState<BusinessFormField["state"]>(initialData.state);
  const [city, setCity] = useState<BusinessFormField["city"]>(initialData.city);
  const [zip, setZip] = useState<BusinessFormField["zip"]>(initialData.zip);
  const [handle, setHandle] = useState<BusinessFormField["handle"]>(initialData.handle);

  const value: Partial<NewBusiness> = useMemo(
    () =>
      Object.entries({
        name: name.value,
        phoneNumber: phoneNumber.value,
        address: address.value,
        additionalAddress: additionalAddress.value,
        country: country.value?.label,
        city: city.value,
        state: state.value,
        zip: zip.value,
        websiteUrl: websiteUrl.value,
        department: department.value,
        handle: handle.value,
      })
        .filter(([key]) => !fields || fields.includes(key as keyof NewBusiness))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    [name, phoneNumber, address, additionalAddress, country, state, city, zip, websiteUrl, department, handle, fields],
  );

  const valid = useMemo(() => {
    const fieldsToTest = Object.entries({
      name,
      phoneNumber,
      address,
      additionalAddress,
      country,
      state,
      city,
      zip,
      department,
      websiteUrl,
      handle,
    })
      .filter(([key]) => !fields || fields.includes(key as keyof NewBusiness))
      .map(([, value]) => value);

    return fieldsToTest.every(({ valid }) => valid !== false);
  }, [
    name,
    phoneNumber,
    address,
    additionalAddress,
    country,
    state,
    city,
    zip,
    department,
    websiteUrl,
    handle,
    fields,
  ]);

  const handleInputError = useCallback((value: string, error: string) => {
    if (error === "patternMismatch") {
      return "Allowed characters A-Z, a-z, 0-9, -, Minimum length is 3 and maximum length is 15 characters. Should start with a letter.";
    }

    return defaultErrorParser(
      value,
      error,
      {
        minLength: 3,
      },
      "",
    );
  }, []);

  useEffect(() => {
    onChange(value, valid);
  }, [value, onChange, valid]);

  const validateHandle = useCallback((value: string) => {
    if (!businessHandleValidation.test(value)) {
      return "patternMismatch";
    }

    return undefined;
  }, []);

  return (
    <form aria-label={title} autoComplete="on" lang="en">
      {!fields || fields.includes("handle") ? (
        <fieldset className={clsx(styles.gridForm, styles.twoCol)}>
          <div className={styles.businessHandle}>
            <FormInput
              label="Business ID"
              name="handle"
              className={styles.businessHandleInput}
              value={handle.value}
              validate={validateHandle}
              errorParser={handleInputError}
              onChange={(value, valid) => setHandle({ value, valid })}
              required
              disabled={disabled}
            />
            <Popover>
              <Popover.Button data-testid="businessHandlePreview" className={styles.infoButton}>
                <InfoIcon size="sm" color="ok" />
              </Popover.Button>
              <Popover.Panel className={styles.infoContent}>
                <dl>
                  <dd>Business ID is used to uniquely identify your business on Lens applications</dd>
                </dl>
              </Popover.Panel>
            </Popover>
          </div>
        </fieldset>
      ) : null}
      <fieldset className={clsx(styles.gridForm, { [styles.twoCol]: !singleColumn })}>
        {!fields || fields.includes("name") ? (
          <FormInput
            label="Organization Name"
            name="organization"
            autoComplete="organization"
            value={name.value}
            onChange={(value, valid) => setName({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("department") ? (
          <FormInput
            label="Department Name"
            name="department"
            autoComplete="department"
            value={department.value}
            onChange={(value, valid) => setDepartment({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("websiteUrl") ? (
          <FormInput
            label="Website URL"
            name="websiteUrl"
            autoComplete="websiteUrl"
            value={websiteUrl.value}
            onChange={(value, valid) => setWebsiteUrl({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("address") ? (
          <FormInput
            label="Address Line"
            name="address-line1"
            autoComplete="address-line1"
            value={address.value}
            onChange={(value, valid) => setStreet({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("additionalAddress") ? (
          <FormInput
            label="Address Line 2"
            name="address-line2"
            autoComplete="address-line2"
            value={additionalAddress.value}
            onChange={(value, valid) => setAdditionalAddress({ value, valid })}
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("country") ? (
          <FormCombobox
            label="Country"
            name="country-name"
            options={countryOptions}
            value={country.value}
            onChange={(value, valid) => setCountry({ value, valid })}
            autoComplete="off"
            translate="no"
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("city") ? (
          <FormInput
            label="City"
            name="address-level2"
            autoComplete="address-level2"
            value={city.value}
            onChange={(value, valid) => setCity({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("state") ? (
          <FormInput
            label="State / Province"
            name="address-level1"
            autoComplete="address-level1"
            value={state.value}
            onChange={(value, valid) => setState({ value, valid })}
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("zip") ? (
          <FormInput
            label="Postal / Zip code"
            name="postal-code"
            autoComplete="postal-code"
            value={zip.value}
            onChange={(value, valid) => setZip({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
        {!fields || fields.includes("phoneNumber") ? (
          <FormInput
            label="Telephone number"
            name="tel"
            autoComplete="tel"
            type="tel"
            value={phoneNumber.value}
            onChange={(value, valid) => setPhoneNumber({ value, valid })}
            required
            disabled={disabled}
          />
        ) : null}
      </fieldset>
      {error?.message && <Notification level="error" message={error.message} type="flash" />}
    </form>
  );
};

export default BusinessIdForm;
