import Page from "src/components/Page/Page";
import { ButtonBar, Panel } from "@k8slens/lds";
import LinkButton from "src/components/Button/TrackedRouterButton";
import sadRobot from "src/img/sad-robot.png";
import PublicLayout from "src/components/PublicLayout/PublicLayout";

export const NotFound = () => (
  <PublicLayout size="lg">
    <Page showLogo={false}>
      <Panel
        header="Page not found"
        headerComponent="h2"
        headerProps={{ className: "lds-h1" }}
        footerComponent={ButtonBar}
        footerProps={{
          as: "footer",
          type: "centered",
        }}
        footer={<LinkButton to="/home" label="Return to home page" primary />}
      >
        <img src={sadRobot} alt="Page not found" />
      </Panel>
    </Page>
  </PublicLayout>
);
