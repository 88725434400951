import { useCallback, useContext, useState } from "react";
import { LoadingIndicator, Notification } from "@k8slens/lds";
import { type BusinessUpdate } from "lens-platform-sdk";

import { type NewBusiness, useBusiness } from "src/hooks/useBusiness";
import { useAnalytics } from "src/hooks/useAnalytics";

import BusinessIdForm from "src/components/BusinessIdForm/BusinessIdForm";
import { BusinessContext } from "src/components/Business/Base";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageAction from "src/components/PageAction/PageAction";
import AvatarFormComponent from "src/components/AvatarFormComponent/AvatarFormComponent";

import styles from "./page.module.css";

const updateButtonLabel = "Update Profile";
const profileFields: Array<keyof NewBusiness> = ["name", "websiteUrl"];

const BusinessProfile = () => {
  const { trackButtonClicked } = useAnalytics();
  const { businessId, business: initialBusiness, loading: loadingBusiness } = useContext(BusinessContext);
  const { updateBusiness, errorUpdatingBusiness, updatingBusiness, updateBusinessSuccess } = useBusiness(businessId);
  const [formValid, setFormValid] = useState(false);

  const [businessUpdate, setBusinessUpdate] = useState<BusinessUpdate>();

  const handleChange = useCallback((formData: BusinessUpdate, valid: boolean) => {
    setFormValid(valid);

    setBusinessUpdate(formData);
  }, []);

  const saveBusiness = () => {
    if (!businessUpdate) {
      return;
    }

    trackButtonClicked(updateButtonLabel);

    updateBusiness(businessUpdate);
  };

  return (
    <div className={styles.businessProfile}>
      {errorUpdatingBusiness ? (
        <Notification flashDuration={1000} level="error" message={errorUpdatingBusiness.message} type="flash" />
      ) : null}
      {updateBusinessSuccess ? (
        <Notification flashDuration={1000} level="success" message="Profile updated" type="flash" />
      ) : null}
      <PageHeader
        title="Profile"
        subtitle={
          <>
            Manage your Lens Business ID <strong>{`(${initialBusiness?.handle || "-"})`}</strong> profile information in
            here.
          </>
        }
      />
      <section className={styles.content}>
        {initialBusiness && (
          <>
            <BusinessIdForm
              title="Edit your new Lens Business ID details."
              onChange={handleChange}
              initialData={initialBusiness}
              singleColumn
              fields={profileFields}
            />
            <AvatarFormComponent user={{ username: businessUpdate?.name || initialBusiness.name }} />
          </>
        )}
      </section>
      <PageAction
        label={updateButtonLabel}
        buttonProps={{
          disabled: !formValid,
          loading: loadingBusiness || updatingBusiness,
          onClick: saveBusiness,
          id: "save",
        }}
      />
      {(updatingBusiness || loadingBusiness) && <LoadingIndicator size="xxl" className={styles.loadingIndicator} />}
    </div>
  );
};

export default BusinessProfile;
