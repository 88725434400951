import clsx from "clsx";
import { ButtonProps } from "@k8slens/lds/lib/es/Button/Button";

import TrackedButton from "src/components/Button/TrackedButton";

import styles from "./PageAction.module.css";

type Props = {
  label: ButtonProps["label"];
  type?: ButtonProps["type"];
  buttonProps?: Omit<ButtonProps, "type" | "label">;
  className?: string;
};

const PageAction = ({ type = "primary", label, buttonProps, className }: Props) => {
  return (
    <div className={clsx(styles.pageAction, className)}>
      <TrackedButton {...buttonProps} type={type} label={label} size="sm" />
    </div>
  );
};

export default PageAction;
