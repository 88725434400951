import { useKeycloak } from "@react-keycloak/web";

export const useUserId = () => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return {
      userId: undefined,
      loading: true,
    };
  }

  return {
    userId: keycloak.tokenParsed?.sub,
    loading: true,
  };
};
