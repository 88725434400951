import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Panel, ButtonBar, Notification } from "@k8slens/lds";

import { useActivation } from "src/hooks/useActivation";
import { getPlanData, getPlanName, PlanCode } from "src/services/plans";
import { useLicenseActivation } from "src/hooks/useActivateLicense";
import { TokenContext } from "src/providers/token-provider";
import { ProfileContext } from "src/providers/profile-provider";
import { useAnalytics } from "src/hooks/useAnalytics";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import LicenseImage from "src/components/LicenseImage/LicenseImage";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import FeatureList from "src/components/FeatureList/FeatureList";
import CurrentUser from "src/components/CurrentUser/CurrentUser";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import styles from "./Activate.module.css";
import { useBusinessInvitation } from "src/hooks/useBusinessInvitation";

const Activate: React.FC = () => {
  const { track, trackError } = useAnalytics();
  const history = useHistory();
  const { activateLicense, licenseError, activateBusinessSubscription } = useLicenseActivation();
  const { updateToken } = useContext(TokenContext);
  const { profile } = useContext(ProfileContext);
  const { activation, error } = useActivation();
  const { businessInvitation } = useBusinessInvitation(activation?.businessId, activation?.invitationId);
  const type = "pro";

  const username = profile?.username;

  const handleActivate = async () => {
    if (activation?.businessId && activation?.invitationId && activation.subscriptionId) {
      const active = await activateBusinessSubscription(
        activation.subscriptionId,
        activation.invitationId,
        activation.businessId,
      );

      if (active) {
        track("Lens Business Subscription Activated", {
          subscriptionId: activation.subscriptionId,
        });
        updateToken(-1);
        history.push("/home");

        return true;
      }
      trackError("Lens Business Subscription Activation Failed", {
        subscriptionId: activation.subscriptionId,
      });

      return true;
    }

    if (username && activation?.subscriptionId) {
      const active = await activateLicense(activation.subscriptionId, type);

      if (active) {
        track("Lens Subscription Activated", { subscriptionId: activation.subscriptionId });
        updateToken(-1);
        history.push("/home");

        return true;
      }
      trackError("Lens Subscription Activation Failed", {
        subscriptionId: activation.subscriptionId,
      });
    }

    return false;
  };

  const planCode = activation?.planCode || "pro-monthly";
  const planData = getPlanData(planCode as PlanCode);
  const invalidKey = Boolean(error) || !activation;

  return (
    <PublicLayout size="xl" className={styles.activate} footer={<CurrentUser />}>
      <Panel
        className={styles.panel}
        header={
          <>
            <h2>Accept Lens Subscription</h2>
            {!invalidKey && (
              <p>
                You have been assigned with <strong>{getPlanName(activation?.planCode)}</strong> Lens Subscription by{" "}
                <strong>{businessInvitation?.business?.name}</strong>.
              </p>
            )}
          </>
        }
        footer={
          <ButtonBar type="grid" gridSize={4}>
            <LinkButton to="/home" label="Cancel" discreet />
            <div />
            <div />
            <Button
              label="Accept"
              onClick={handleActivate}
              loadingStateOnPromise
              disabled={invalidKey || !username}
              primary
            />
          </ButtonBar>
        }
      >
        <>
          {licenseError && <Notification level="error" message={licenseError} type="flash" />}
          {invalidKey && (
            <div className={styles.error}>
              <h4>Something went wrong</h4>
              <p>There seems to be some issues with your activation key.</p>
              <p>
                Try re-opening the link in your activation email. If that doesn&apos;t help, please contact us at{" "}
                <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
              </p>
            </div>
          )}
          {!invalidKey && (
            <div className={styles.planInfo}>
              <div>
                <LicenseImage type={planData.type} />
              </div>
              <FeatureList title={`Included in Lens ${planData.nameShort}`} features={planData.features} />
            </div>
          )}
        </>
      </Panel>
    </PublicLayout>
  );
};

export default Activate;
